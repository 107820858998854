import tw from "twin.macro"
import copy from "../../../data/education_results.yml"

const EducationTabs: React.FC<{activeTab: number, toggleTabs: any}> = ({activeTab, toggleTabs}) => {
    return (
        <nav css={activeTab == 3 ? [tw`w-full bg-red-300 h-48 md:h-56 mt-36 sm:mt-24 lg:mt-2`] : [tw`w-full bg-red-300 h-48 md:h-56`]}>
        <div
          className="no-scrollbar md:none"
          css={[
            tw`mx-auto overflow-x-scroll overflow-y-visible md:overflow-visible content-end`,
          ]}
        >
          <ul
            css={[
              tw`items-end flex flex-row md:flex-none md:grid md:grid-cols-12 gap-2`,
            ]}
          >
            <li
              css={[
                tw`cursor-pointer text-white text-xl font-bold inline-block relative w-44 md:w-auto md:col-span-4`,
              ]}
              className="tab"
            >
              <div
                css={
                  activeTab == 1
                    ? [
                        tw`bg-red-300 h-32 md:h-40 w-44 md:w-auto shadow-xl scale-y-110 relative`,
                      ]
                    : [tw`bg-red-500 w-44 md:w-auto h-32 md:h-40 relative`]
                }
              ></div>
              <div
                onClick={() => toggleTabs(1)}
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 mx-auto grid grid-cols-1 items-center content-center md:px-8 md:py-6 text-center z-30`,
                ]}
              >
                <p css={[tw`text-sm md:text-xl`]}>{copy.tabs[0].label}</p>
                <h1 css={[tw`text-3xl md:text-5xl`]} className="subhead">
                {copy.tabs[0].CTA}
                </h1>
              </div>
              <div
                className="oval"
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 h-24 w-40 md:h-32 md:w-full`,
                ]}
              ></div>
              <div
                css={
                  activeTab == 1
                    ? [
                        tw`h-3 w-4 left-0 right-0 top-[-30px] mx-auto absolute border-b-red-300 border-b-[24px] border-r-transparent border-r-[50px] border-l-transparent border-l-[50px]`,
                      ]
                    : null
                }
              ></div>
            </li>
            <li
              css={[
                tw`cursor-pointer text-white text-xl font-bold inline-block relative w-44 md:w-auto md:col-span-4`,
              ]}
              className="tab"
            >
              <div
                css={
                  activeTab == 2
                    ? [
                        tw`bg-red-300 h-32 md:h-40 w-44 md:w-auto shadow-xl scale-y-110 relative`,
                      ]
                    : [tw`bg-red-500 w-44 md:w-auto h-32 md:h-40 relative`]
                }
              ></div>
              <div
                onClick={() => toggleTabs(2)}
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 mx-auto grid grid-cols-1 items-center content-center md:px-8 md:py-6 text-center z-30`,
                ]}
              >
                <p css={[tw`text-sm md:text-xl`]}>{copy.tabs[1].label}</p>
                <h1 css={[tw`text-3xl md:text-5xl`]} className="subhead">
                {copy.tabs[1].CTA}
                </h1>
              </div>
              <div
                className="oval"
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 h-24 w-40 md:h-32 md:w-full`,
                ]}
              ></div>
              <div
                css={
                  activeTab == 2
                    ? [
                        tw`h-3 w-4 left-0 right-0 top-[-30px] mx-auto absolute border-b-red-300 border-b-[24px] border-r-transparent border-r-[50px] border-l-transparent border-l-[50px]`,
                      ]
                    : null
                }
              ></div>
            </li>
            <li
              css={[
                tw`cursor-pointer text-white text-xl font-bold inline-block relative w-44 md:w-auto md:col-span-4`,
              ]}
              className="tab"
            >
              <div
                css={
                  activeTab == 3
                    ? [
                        tw`bg-red-300 h-32 md:h-40 w-44 md:w-auto shadow-xl scale-y-110 relative`,
                      ]
                    : [tw`bg-red-500 w-44 md:w-auto h-32 md:h-40 relative`]
                }
              ></div>
              <div
                onClick={() => toggleTabs(3)}
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 mx-auto grid grid-cols-1 items-center content-center md:px-8 md:py-6 text-center z-30`,
                ]}
              >
                <p css={[tw`text-sm md:text-xl`]}>{copy.tabs[2].label}</p>
                <h1 css={[tw`text-3xl md:text-5xl`]} className="subhead">
                {copy.tabs[2].CTA}
                </h1>
              </div>
              <div
                className="oval"
                css={[
                  tw`absolute top-0 bottom-0 left-0 right-0 h-24 w-40 md:h-32 md:w-full`,
                ]}
              ></div>
              <div
                css={
                  activeTab == 3
                    ? [
                        tw`h-3 w-4 left-0 right-0 top-[-30px] mx-auto absolute border-b-red-300 border-b-[24px] border-r-transparent border-r-[50px] border-l-transparent border-l-[50px]`,
                      ]
                    : null
                }
              ></div>
            </li>
          </ul>
        </div>
      </nav>
    )
}

export default EducationTabs