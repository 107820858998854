import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import { ModalProps } from "./Modal.d"
import MobileBG from "../../images/mobile-background.jpg"
import Logo from "../Logo"
import { Link } from "gatsby"

const Modal: React.FC<ModalProps> = ({
  children,
  modalCss,
  open,
  blue,
  ...remainingProps
}) => {
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(tw`hidden`)

  useEffect(() => {
    if (open) {
      setDisplay(tw`flex`)
      document.body.style.overflow = "hidden"

      setTimeout(() => {
        setShow(open)
      }, 10)
    } else {
      setShow(open)

      setTimeout(() => {
        setDisplay(tw`hidden`)
        document.body.style.overflow = "scroll"
      }, 300)
    }
  }, [open])

  return (
    <div
      css={[
        tw`overflow-y-scroll md:overflow-y-auto p-0 z-50 fixed top-0 left-0 w-full h-full opacity-0 justify-center content-center pointer-events-none  bg-red-300 bg-opacity-[98%]`,
        blue && `background-image: url(${MobileBG})`,
        show && tw`opacity-100 pointer-events-auto bg-center lg:bg-top bg-cover`,
        display,
      ]}
    >
      <div css={[tw``]}>
        <div css={blue ? [tw`flex flex-row justify-between items-center py-8 `] : null}>
          {blue ? <Link
            css={[tw`font-semibold cursor-pointer col-span-1 mx-auto lg:mx-0 lg:col-span-2 z-50`]}
            to="/"
          >
            <Logo />
          </Link> : null}
        </div>
        <div
          css={[
            tw`flex flex-row lg:px-16 justify-center  lg:w-full opacity-0 relative max-w-5xl`,
            show && tw`opacity-100 translate-y-0`,
            ...modalCss,
          ]}
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

Modal.defaultProps = {
  modalCss: [],
}

export default Modal
